import * as React from 'react';
import { Link } from '@plugins/gatsby-plugin-modal-routing';
import { LABEL } from '@core/constants';
import { useDisallowAuthRoutes, useRoutes } from '@core/hooks';
import BaseHead from '@components/Head';
import ModalRoutingConsumer from '@components/auth/ModalRoutingConsumer';
import Title from '@components/auth/Title';
import Header from '@components/auth/Header';
import SignUpForm from '@components/auth/SignUpForm';
import { PageProps } from '@interface/common';


export default function SignUpPage({ isAuthenticating }: PageProps<any>) {
  const disallowAuthRoutes = useDisallowAuthRoutes(isAuthenticating);
  const { route, LOGIN } = useRoutes();

  return (
    <ModalRoutingConsumer
      isLoading={disallowAuthRoutes}
      render={({ modal, closeTo}) => (
        <>
          <Title>{LABEL.SIGNUP}</Title>
          <Header>
            <p>
              {`Already have an account? `}
              <Link
                to={route(LOGIN)}
                asModal={modal}
              >
                {LABEL.LOGIN}
              </Link>.
            </p>
          </Header>
          <SignUpForm
            redirect={closeTo}
            showIdentityButtons
          />
        </>
      )}
    />
  );
}

export function Head() {
  return <BaseHead title={LABEL.SIGNUP}/>;
}
