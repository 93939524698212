import * as React from 'react';
import { navigate } from 'gatsby';
import { Form, Field } from 'react-final-form';
import { toast } from 'react-toastify';
import { isUndefined, startCase } from 'lodash';
import clsx from 'clsx';
import * as api from '@core/api';
import { useUser, useCart, useCaptcha, useRoutes, useCountDown, useAnalytics } from '@core/hooks';
import { ERROR, LABEL } from '@core/constants';
import Validation, {
  focusOnErrorDecorator,
  isEmail,
  isAccepted,
  handleSubmissionError,
  isStrongPassword,
} from '@core/forms';
import Link from '@components/Link';
import Separator from '@components/Separator';
import {
  FacebookIdentityButton,
  GoogleIdentityButton,
  GoogleReCaptcha,
  GoogleReCaptchaDisclaimer,
} from '@components/thirdparty';
import Header from '@components/auth/Header';
import TextInput from '@components/forms/TextInput';
import PasswordInput from '@components/forms/PasswordInput';
import CheckboxInput from '@components/forms/CheckboxInput';
import ButtonSubmit from '@components/forms/ButtonSubmit';
import { API } from '@interface/common';
import * as styles from './SignUpForm.module.scss';


const FORM_ID = 'signup-form';

const schema = new Validation({
  name: {
    required: true,
  },
  email: {
    required: true,
    use: { isEmail },
  },
  password: {
    required: true,
    use: { isStrongPassword },
  },
  consent: {
    use: { isAccepted },
  },
});

schema.setMessages({
  isAccepted: () => 'You must read and agree to the Terms of Use to continue',
});

const focusOnError = focusOnErrorDecorator(FORM_ID);


type FormValues = API.SignUp.Form;
type Props = {
  redirect?: string | null;
  isGuest?: boolean;
  showIdentityButtons?: boolean;
}

export default function SignUpForm({ redirect, isGuest = false, showIdentityButtons = false }: Props) {
  const captchaId = React.useId();
  const { signIn } = useUser();
  const { getCartData } = useCart();
  const { route, ACCOUNT, TERMS, PRIVACY } = useRoutes();
  const captcha = useCaptcha();
  const worker = useCountDown();
  const tracking = useAnalytics();

  const handleSignIn = async (response: API.SignUp.Response) => {
    signIn(response, isGuest);

    if (
      !response.user.isEmailConfirmed &&
      Math.floor((Date.now() - Date.parse(response.user.createdAt!)) / (1000 * 60)) < 1
    ) {
      worker?.start();
    }

    let path = redirect ? redirect : route(ACCOUNT);
    await navigate(path, { state: { isGuest, noScroll: !isUndefined(redirect) }, replace: isGuest });
  };

  const handleProviderSignIn = React.useCallback(handleSignIn, []);

  const handleValidateForm = React.useCallback((values: FormValues) => schema.validate(values), []);

  const handleSubmitForm = React.useCallback(async (values: FormValues): Promise<any> => {
    if (!captcha.execute) {
      toast.error(ERROR.CAPTCHA_NOT_READY);
      return handleSubmissionError(ERROR.CAPTCHA_NOT_READY);
    }

    if (captcha.tokenV2) {
      try {
        const response = await api.signUp(values, { tokenV2: captcha.tokenV2 }, getCartData(isGuest));
        tracking.auth('sign_up', 'Password');
        await handleSignIn(response);
      } catch (error) {
        toast.error(error.message);
        return handleSubmissionError(error);
      }
    }

    try {
      const tokenV3 = await captcha.execute('signup');
      const response = await api.signUp(values, { tokenV3 }, getCartData(isGuest));
      tracking.auth('sign_up', 'Password');
      await handleSignIn(response);
    } catch (error) {
      captcha.reset();
      if (captcha.isLowScore(error) && !captcha.isRendered()) {
        captcha.render(captchaId);
        return false;
      } else {
        toast.error(error.message);
        return handleSubmissionError(error);
      }
    }
  }, [captcha.execute, captcha.tokenV2, captcha.isRendered, getCartData]);

  return (
    <>
      {showIdentityButtons && (
        <>
          <Header noMargin>
            <GoogleIdentityButton
              text="signup"
              onSuccess={handleProviderSignIn}
            />
            <FacebookIdentityButton
              label={LABEL.SIGNUP}
              onSuccess={handleProviderSignIn}
            />
          </Header>
          <Separator>or</Separator>
        </>
      )}
      <Form
        validate={handleValidateForm}
        // @ts-ignore
        decorators={[focusOnError]}
        onSubmit={handleSubmitForm}
        render={({ handleSubmit }) => (
          <form
            id={FORM_ID}
            onSubmit={handleSubmit}
            noValidate
          >
            <Field
              name="name"
              type="text"
              label="Full name"
              placeholder="John Doe"
              autoComplete="on"
              autoCapitalize="words"
              autoCorrect="off"
              spellCheck="false"
              component={TextInput}
              maxLength={100}
              required
            />
            <Field
              name="email"
              type="email"
              label="Email"
              placeholder="john@domain.com"
              autoComplete="on"
              component={TextInput}
              required
            />
            <Field
              name="password"
              type="password"
              label="Password"
              autoComplete="on"
              component={PasswordInput}
              useToggle
              useMeter
              useHint
              required
            />
            <Field
              name="consent"
              type="checkbox"
              component={CheckboxInput}
              label={() => (
                <span className={clsx('format', styles.consent)}>
                  {`I have read and accepted the `}<Link to={route(TERMS)} external>{startCase(LABEL.TERMS)}</Link>
                  {` and `}<Link to={route(PRIVACY)} external>{startCase(LABEL.PRIVACY)}</Link>.
                </span>
              )}
              required
            />
            <GoogleReCaptcha id={captchaId}/>
            <ButtonSubmit
              label="Create account"
              stretch
            />
            <GoogleReCaptchaDisclaimer/>
          </form>
        )}
      />
    </>
  );
}
